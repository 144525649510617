import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurgeListComponent } from './purge-list/purge-list.component';
import { PurgeResultComponent } from './purge-result/purge-result.component';
import { RequireResetPasswordGuard } from '../../core/guards';

export const routes: Routes = [
  {
    path: 'purge',
    canActivate : [ RequireResetPasswordGuard ],
    canActivateChild : [ RequireResetPasswordGuard ],
    children: [
      {
        path: 'list',
        component: PurgeListComponent
      },
      {
        path: 'result',
        component: PurgeResultComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurgeRoutingModule { }
