<div class="datatables-custom-container">
  @if (title) {
    <div class="datatables-custom-title-wrapper">
      <h5>{{ title | translate }}</h5>
    </div>
  }
  <div class="datables-custom-wrapper">
    <p-table
      #datables
      [attr.id]="tableId ?? null"
      class="datatables-custom"
      [dataKey]="dataKey"
      [rows]="limitRow"
      [rowSelectable]="rowSelectable"
      [scrollable]="scrollable"
      [(selection)]="selectedRows"
      tableStyleClass="table"
      [value]="rows"
      [customSort]="customSort"
      [lazy]="true"
      (onSort)="sortRows($event)"
      [loading]="isLoadingRows"
    >
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track column.id; let i = $index) {
            @if (column.frozen && column.alignFrozen) {
              <th
                pFrozenColumn
                [alignFrozen]="column.alignFrozen"
                [id]="column.id + 'Column'"
                [pSortableColumn]="column.sort ? column.name : undefined"
                [style]="column.headerStyle ?? null"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
                [ngClass]="[
                  !column.sort ? 'block-sort-column' : '',
                  column.headerClass ?? ''
                ]"
              >
              @if (column.type === 'checkbox') {
                @if (!blockRenderingCheckboxKey || (blockRenderingCheckboxKey && (rows | showAndHideCheckbox: blockRenderingCheckboxKey : blockRenderingCheckboxBoolean ))) {
                  <p-tableHeaderCheckbox />
                }
              } @else {
                {{ column.label | translate }}
              }
              </th>
            } @else {
              <th
                [id]="column.id + 'Column'"
                [pSortableColumn]="column.sort ? column.name : undefined"
                [style]="column.headerStyle ?? null"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
                [ngClass]="[
                  !column.sort ? 'block-sort-column' : '',
                  column.headerClass ?? ''
                ]"
              >
                @if (column.type === 'checkbox') {
                  @if (!blockRenderingCheckboxKey || (blockRenderingCheckboxKey && (rows | showAndHideCheckbox: blockRenderingCheckboxKey : blockRenderingCheckboxBoolean ))) {
                    <p-tableHeaderCheckbox />
                  }
                } @else {
                  {{ column.label | translate }}
                }
              </th>
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr>
          @for (column of columns; track column.id;) {
            @if (column.frozen && column.alignFrozen) {
              <td
                pFrozenColumn
                [attr.data-testid]="column.id + (rowIndex + 1) + 'Cell'"
                [alignFrozen]="column.alignFrozen"
                [class]="column.cellClass || ''"
                [id]="column.id + rowIndex + 'Cell'"
                [style]="column.cellStyle ?? null"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
                [ngClass]="{ 'last-cell-border': (rowIndex + 1) === rows.length }"
              >
                @if (column.type === 'checkbox') {
                  @if ((blockRenderingCheckboxKey && data[blockRenderingCheckboxKey]) !== blockRenderingCheckboxBoolean) {
                    <p-tableCheckbox [value]="data" />
                  }
                } @else {
                  <ng-container
                    [ngTemplateOutlet]="columnEl"
                    [ngTemplateOutletContext]="{ rowIndex: rowIndex, column: column, data: data }"
                  />
                }
              </td>
            } @else {
              <td
                [attr.data-testid]="column.id + (rowIndex + 1) + 'Cell'"
                [id]="column.id + rowIndex + 'Cell'"
                [class]="column.cellClass || ''"
                [style]="column.cellStyle ?? null"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
                [ngClass]="{ 'last-cell-border': (rowIndex + 1) === rows.length }"
              >
                @if (column.type === 'checkbox') {
                  @if ((blockRenderingCheckboxKey && data[blockRenderingCheckboxKey]) !== blockRenderingCheckboxBoolean) {
                    <p-tableCheckbox [value]="data" />
                  }
                } @else {
                  <ng-container
                    [ngTemplateOutlet]="columnEl"
                    [ngTemplateOutletContext]="{ rowIndex: rowIndex, column: column, data: data }"
                  />
                }
              </td>
            }
          }
        </tr>
      </ng-template>
      
    </p-table>
    @if (rows.length > 0 && !isLoadingRows) {
      <div class="pagination-info-wrapper">
        {{ 'NEW-TRANSLATE.COMMON.TOTAL' | translate }} {{  { limitRow: limitRow, pages: pages, collectionSize: collectionSize, rowCount: rows.length} | totalPages }} @if (limitRow) { {{ 'NEW-TRANSLATE.COMMON.OF' | translate }} {{ collectionSize }} }
      </div>
    }
    @if (rows.length === 0 && !isLoadingRows) {
      <div class="data-not-found-wrapper">
        <h5>{{ 'NEW-TRANSLATE.COMMON.DATA-NOT-FOUND' | translate }}</h5>
      </div>
    }
    @if (isLoadingRows) {
      <div class="loading-wrapper">
        <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="2xl"></fa-icon>
      </div>
    }
  </div>
</div>

<ng-template #columnEl let-data="data" let-rowIndex="rowIndex" let-column="column">
  @switch (column.type) {
    @case ('no') {
      {{((pages - 1) * limitRow) + rowIndex + 1}}
    }
    @case ('string') {
      @if (column.multiLang) {
        {{ data[column.name + ( language === 'th' ? '_th' : '_en')] ?? column.defaultValue }}
      } @else {
        {{ (data[column.name] ?? column.defaultValue) | translate }}
      }
    }
    @case ('multi-condition') {
      {{ ((column.multiConditions ?? [] | findByKey: 'key' : data[column.name]) ?? column.defaultValue) | translate }}
    }
    @case ('boolean-icon') {
      @if (data[column.name]) {
        <fa-icon [icon]="['fas', 'check']" aria-hidden="true"></fa-icon>
      } @else {
        {{ (column.defaultValue | translate) ?? null }}
      }
    }
    @case ('document-type') {
      {{ (data[column.name] | documentType: language: 'name' ) ?? column.defaultValue }}
    }
    @case ('join-string') {
      {{ ((data | joinString: column.joinString?.keys : column.joinString?.separator) ?? column.defaultValue) | translate }}
    }
    @case ('date') {
      {{ (data[column.name] | transformDate: column.format) ?? column.defaultValue }}
    }
    @case ('dot-color') {
      <fa-icon
        [icon]="['fas', 'circle']"
        size="sm"
        [style.color]="((column.multiConditions ?? [] | findByKey: 'key' : data[column.name]) ?? column.defaultValue)"
      ></fa-icon>
    }
    @case ('month') {
      @let month = (data[column.name] | month);
      @if (month) {
        {{ language === 'th' ? month.name_th : month?.name_en }}
      } @else {
        {{ column.defaultValue }}
      }
    }
    @case ('status') {
      <app-status
        [statusId]="data[column.name]"
        statusWidth="120px"
      />
    }
    @case ('number') {
      {{ (data[column.name] | number: column.format) ?? column.defaultValue }}
    }
    @case ('total-document') {
      <div
        class="text-center"
      >
       
        @if (column?.totalDocument?.view) {
          <div class="btn btn-dv btn-clean btn-compact total-document-btn d-flex gap-2 justify-content-center">
            {{ (data[column.name] ?? column.defaultValue) | number: '0.0-0' }} <fa-icon [icon]="['fas', 'file-lines']" size="lg"></fa-icon>
          </div >
        } @else {
          <button
            class="btn btn-dv btn-clean btn-compact total-document-btn d-flex gap-2 justify-content-center"
            [disabled]="!data[column?.totalDocument?.disabled]"
            [title]="column.label | translate"
            (click)="onDownloadTotalDocument({ row: data, index: rowIndex, action: column.name, loadingElement: column?.lazyLoading ? lazyLoadingVerifyEl : undefined })"
          >
            <div
              #lazyLoadingVerifyEl
              class="d-none"
            >
              <fa-icon
                class="spinner-border"
                [icon]="['fas','spinner']"
                size="lg"
              ></fa-icon>
            </div>
            {{ (data[column.name] ?? column.defaultValue) | number: '0.0-0' }} <fa-icon [icon]="['fas', 'file-lines']" size="lg"></fa-icon>
          </button>
        }
      </div>
    
    }
    @case ('verify') {
      @if (!column.hideOption || (column.hideOption && data[column.hideOption.key] !== column.hideOption.condition)) {
        <div
          #lazyLoadingVerifyEl
          class="d-none text-center"
        >
          <fa-icon
            class="spinner-border"
            [icon]="['fas','spinner']"
            size="lg"
          ></fa-icon>
        </div>
        <div
          #checkBoxWrapperEl
          class="text-center"
        >
          <p-checkbox
            class="dv-checkbox"
            binary="true"
            [(ngModel)]="data[column.name]"
            (onChange)="onVerify($event, { row: data, index: rowIndex, action: column.name, loadingElement: column?.lazyLoading ? lazyLoadingVerifyEl : undefined, targetElement: checkBoxWrapperEl })"
          ></p-checkbox>
        </div>
      }
    }
    @case ('button') {
      @switch (column.format) {
        @case('resend-email') {
          <button
            class="btn btn-dv btn-send-email d-flex align-items-center justify-content-center mx-auto"
            [disabled]="isLoadingOther"
            [title]="column.label | translate"
            (click)="onRowAction({ row: data, index: rowIndex, action: column.name, loadingElement: column?.lazyLoading ? lazyLoadingActionIconEl : undefined })"
          >
            <div
              #lazyLoadingActionIconEl
              class="d-none me-2"
            >
              <fa-icon
                class="spinner-border"
                [icon]="['fas','spinner']"
                size="lg"
              ></fa-icon>
            </div>
            <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
          </button>
        }
        @case ('download-zip') {
          <button
            class="btn btn-dv btn-clean btn-compact btn-download-zip btn-wide d-flex align-items-center justify-content-center"
            [disabled]="isLoadingOther"
            [title]="column.label | translate"
            (click)="onRowAction({ row: data, index: rowIndex, action: column.name, loadingElement: column?.lazyLoading ? lazyLoadingActionIconEl : undefined })"
          >
            <div
              #lazyLoadingActionIconEl
              class="d-none me-2"
            >
              <fa-icon
                class="spinner-border"
                [icon]="['fas','spinner']"
                size="lg"
              ></fa-icon>
            </div>
            Zip
          </button>
        }
      }
    }
    @case ('action-icon') {
      @if (data[column?.actionIconOptions?.condition] || !column?.actionIconOptions?.condition) {
        <div
          #lazyLoadingActionIconEl
          class="d-none text-center"
        >
          <fa-icon
            class="spinner-border"
            [icon]="['fas','spinner']"
            size="lg"
          ></fa-icon>
        </div>
        <button
          #actionIconEl
          class="btn-icon"
          [disabled]="isLoadingOther"
          [title]="column.label | translate"
          (click)="onRowAction({ row: data, index: rowIndex, action: column.name, loadingElement: column?.lazyLoading ? lazyLoadingActionIconEl : undefined, targetElement: actionIconEl })"
        >
          @switch (column.format) {
            @case('eye') {
              <fa-icon
                aria-hidden="true"
                [icon]="['far', 'eye']"
                [style]="column?.actionIconOptions?.style ?? null"
                size="lg"
              ></fa-icon>
            }
            @case('file-alt') {
              <fa-icon
                aria-hidden="true"
                [icon]="['fas','file-alt']"
                [style]="column?.actionIconOptions?.style ?? null"
                size="xl"
              ></fa-icon>
            }
            @case('pdf') {
              <img
                src="assets/images/pdf.png"
                alt="PDF"
              >
            }
            @case('xml') {
              <img
                src="assets/images/xml.png"
                alt="XML"
              >
            }
          }
        </button>
      }   
    }
    @default {
      Unknow
    }
  }
</ng-template>