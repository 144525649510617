<div class="box-filter position-relative z-2">
  <div class="header">
    <div class="row">
      <div class="col-12 text-right">
        <a class="h3" href="javascript:void(0)" (click)="back()">
          <fa-icon [icon]="['fas', 'circle-xmark']" class="text-red"></fa-icon>
        </a>
      </div>
    </div>
    <p-toast position="top-right"></p-toast>
    <div class="row mt-2">
      <div class="col-6">
        <h3 id="pageTitle">{{ 'DATA-PURGE.PENDING' | translate }}</h3>
        <h5>
          {{ 'DATA-PURGE.TOTAL-NUMBER-TO-DELETE' | translate }} {{ totalItems | number }} {{ 'TEXT.FILE'| translate }} |
          {{ 'TEXT.SIZE' | translate }} {{ sizeCount }}
        </h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6 text-left">
        <button type="button" class="btn btn-dv btn-wide" (click)="open(confirmModal)" *ngIf="!isStart">
          {{ 'DATA-PURGE.START-PURGE' | translate }}
        </button>
        <button type="button" class="btn btn-dv btn-wide" (click)="stopTimer()" *ngIf="isStart">
          {{ 'DATA-PURGE.STOP-PURGE' | translate }}
        </button>
        <button type="button" class="btn btn-dv" (click)="deselectFiles()">
          {{ 'DATA-PURGE.DESELECT-FILES' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="box-parent position-relative z-1">
  <table class="table table-center" aria-describedby="pageTitle">
    <thead>
      <tr>
        <th>
          <p-checkbox
            class="dv-checkbox"
            binary="true"
            (onChange)="toggleAllCheckbox($event)"
            [(ngModel)]="checkAll"
            [disabled]="isStart"
            >
          </p-checkbox>
        </th>
        <th class="text-left">{{ 'DATA-PURGE.FILE-NAME' | translate }}</th>
        <th>{{ 'BILLING.FILE-SIZE' | translate }}</th>
        <th class="text-center">{{ 'EMAIL-DASHBOARD.STATUS' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of fileList; let itemIndex = index"
        [ngClass]="{'d-none': !item.csv_file}">
        <td style="width: 10%;" class="v-middle">
          <p-checkbox
            class="dv-checkbox"
            binary="true"
            (onChange)="toggleNormalCheckbox($event)"
            [(ngModel)]="item.is_checked"
            [disabled]="isStart"
            >
          </p-checkbox>
        </td>
        <td class="text-left" class="v-middle">{{ item.csv_name || '-' }}</td>
        <td style="width: 15%;" class="v-middle">{{ item.file_size_label }}</td>
        <td style="width: 10%;"class="text-center v-middle">
          <fa-icon *ngIf="isSpin[itemIndex]" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
        </td>
      </tr>
      <tr *ngIf="!fileList.length" class="v-middle">
        <td colspan="4" class="text-center">{{ 'DATA-PURGE.NO-FILE-LEFT' | translate }}</td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #confirmModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="text-center">
      {{ 'DATA-PURGE.CONFIRM-PURGE-FILE' | translate }}
    </h5>
  </div>
  <div class="modal-footer-alternative ">
    <button class="btn btn-dv btn-wide" (click)="confirm()">
      {{'COMMON.OK' | translate}}
    </button>
    <button class="btn btn-dv btn-clean btn-wide" (click)="c()">
      {{'COMMON.CANCEL' | translate}}
    </button>
  </div>
</ng-template>
