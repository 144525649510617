import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public get(url: string, params?: any): Observable<Object> {
    return this.httpClient.get(url, {params: params })
  }

  public getBlob(url: string, params?: any): Observable<Object> {
    return this.httpClient.get(url, {params: params, responseType: 'blob'})
  }

  public post(url: string, data: any, params?: any): Observable<Object> {
    return this.httpClient.post(url, data, { params: params })
  }

  public postBlob(url: string, data: any): Observable<Object> {
    return this.httpClient.post(url, data, { responseType: 'blob'})
  }

  public put(url: string, data: any, params?: any): Observable<Object> {
    return this.httpClient.put(url, data, { params: params })
  }

  public patch(url: string, data: any): Observable<Object> {
    return this.httpClient.patch(url, data)
  }

  public delete(url: string): Observable<Object> {
    return this.httpClient.delete(url)
  }

  public createDownloadFile(blob: Blob, filename: string): void {
    const navigator = window.navigator as any;
    if (navigator && navigator?.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const el = document.createElement('a');
      document.body.appendChild(el);
      const url = window.URL.createObjectURL(blob);
      el.setAttribute('href', url);
      el.setAttribute('download', filename);
      el.style.display = 'none';
      el.click();
      document.body.removeChild(el);
    }
  }

}
