import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, zip } from 'rxjs';
import { SubSink } from 'subsink';
import { PurgeService } from '../../../service/purge.service';
import { ApiService } from '../../../service/api.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../service/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import {
  set as lodashSet,
  remove as lodashRemove,
  clone as lodashClone,
  sumBy as lodashSumBy
} from 'lodash';
import { UtilityService } from '../../../service/utility.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'app-purge-result',
  templateUrl: './purge-result.component.html',
  styleUrl: './purge-result.component.scss'
})
export class PurgeResultComponent implements OnInit, OnDestroy {

  public totalItems = 0;
  public sizeCount = '0 Byte';
  public fileList: any[] = [];
  public isSpin: any[] = [];
  public errorCount: number = 0;
  public timer: any;
  public isStart: boolean = false;
  public isStop: Subject<boolean> = new Subject<boolean>();
  public fileSubscribe: Subscription | undefined;
  public modal: NgbModalRef | undefined;
  public stopPurge: boolean = false;
  public checkAll: boolean = false;
  public csvId: any = null;

  private subs = new SubSink();

  constructor(
    private modalService: NgbModal,
    private purgeService: PurgeService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.getCSVList();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getCSVList() {
    const params = new HttpParams()
      .set('none_pagination', 'True')
      .set('is_purged_selected', 'True');
    this.subs.add(
      this.purgeService.getCSVList(params)
      .subscribe({
        next: (res: any) => {
          const convertedFileList = res
            .filter((obj: any) => obj.csv_file)
            .map((obj: any) => {
              lodashSet(obj, 'file_size_label', this.formatBytes(obj['file_size'], 3));
              lodashSet(obj , 'is_checked' , false);
              return obj;
            });
          this.fileList = convertedFileList;
          this.calculateFileCountAndSize();
          },
        error: (err) => {
          console.error('error', err);
        }
      })
    );
  }

  toggleNormalCheckbox(event: CheckboxChangeEvent) {
    if (!event.checked) {
      this.checkAll = false;
      return;
    }

    let allCheckBoxAreChecked = true;
    for (const obj of this.fileList) {
      if (obj['is_checked'] === false) {
        allCheckBoxAreChecked = false;
        break;
      }
    }
    if (allCheckBoxAreChecked){
      this.checkAll = true;
    }
  }

  toggleAllCheckbox(event: CheckboxChangeEvent){
    for (const i in this.fileList){
      this.fileList[i]['is_checked'] = event.checked;
    }
  }

  formatBytes(bytes: any, decimals: any): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  deselectFiles(): void {
    if (this.isStart){
      return;
    }
    let deselectedFileList = [];
    for (const obj of this.fileList){
      if (obj['is_checked']){
        deselectedFileList.push(obj['id']);
      }
    }

    if (deselectedFileList.length){
      const data = {
        tax_data_list: deselectedFileList,
        is_purged_selected: false
      };
      this.purgeService
        .patchTaxImport(data)
        .subscribe({
          next: (res: any) => {
            lodashRemove(this.fileList , obj => deselectedFileList.includes(obj['id']));
            this.notificationService.showSuccess('succesfully deselecting files');
          },
          error: (err) => {
            this.notificationService.showErrorNotification(err.error);
          }
        });
    }
  }

  deleteCsvXmlPdfFile(tax_import: any, loobIndex: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subs.add(
        this.purgeService
          .deleteTaxImport(tax_import.id)
          .subscribe({
            next: (res) => {
              this.isSpin = [];
              this.isSpin[loobIndex + 1] = false;
              tax_import.csv_file = null;
              this.calculateFileCountAndSize();
              resolve();
            },
            error: (err) => {
              this.isSpin = [];
              this.isSpin[loobIndex + 1] = false;
              this.calculateFileCountAndSize();
              reject(err);
            }
          })
      );
    }).catch((error) => {
      console.error(error.error);
    });
  }

   purgeFile(): void {
    this.csvId = this.fileList.filter(file => file.is_checked === true);
    this.fileList = this.fileList.filter(obj => obj.csv_file);
      if (!this.fileList.length) {
        this.isStart = false;
        return;
      }

      this.isSpin[0] = true;
      this.isStart = true;
      var index = 0;
      for(var i in  this.csvId){
        var file = this.csvId[i];
        this.purge(file,index);
        index++;

      }
    this.stopTimer();
    this.fileList = this.fileList.filter(obj => obj.csv_file);
  }

  purge(file: any,index: any): void {
    setTimeout(()=> {
      this.downloadCSVFile(file.csv_file, file.csv_name);
      this.getPdfXmlFileUrl(file);
      alert('Continue to download');
      this.deleteCsvXmlPdfFile(file, index);
    }, 3000 * index);
  }

  getPdfXmlFileUrl(tax_import: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const tax_import_id = tax_import.id;
      const zip_file_name = 'csv_name' in tax_import && tax_import.csv_name ?
        tax_import.csv_name.replace(/\.[^/.]+$/, '') + '_xml_pdf_files' :
        '';
      const data = {
        tax_import_id : tax_import_id,
        zip_file_name : zip_file_name
      };
      const params = new HttpParams().append('purge', 'True');
      this.subs.add(
        this.apiService
          .downloadZipFile(data, params)
          .subscribe({
            next: (res: any) => {
              if (res.zip_file) {
                this.downloadFile(lodashClone(res.zip_file));
                resolve();
              }
              this.translateService.get('DATA-PURGE')
                .subscribe(x => {
                  reject({'error' : `${ x['NO-XML-PDF']} ${tax_import.csv_name}`});
                })
            },
            error: (err) => {
              reject(err);
            }
          })
      );

    }).catch((error) => {
      this.notificationService.showCustomNotification(
          {
            severity : 'info' ,
            detail : error.error
          }
        );
    });
  }

  downloadCSVFile(file: any, name: any): void {
    this.purgeService
      .downloadCSVFile(file)
      .subscribe({
        next: (res: any) => {
          const blob = new Blob([res], {type: 'text/csv'});
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = name;
          a.click();
        }
      });
  }
  downloadFile(csvFile: any): void {
    if (this.utilityService.isUrlExists(csvFile)) {
      window.location.assign(csvFile);
    } else {
      this.translateService
        .get('DATA-PURGE')
        .subscribe((res) => {
          this.notificationService.showCustomNotification({
            severity: 'info',
            summary: res['value']['INFO'],
            detail: res['value']['FILE-NOT-EXISTS']
          });
        });
    }
  }

  stopTimer(): void {
    this.isSpin = [];
    this.isStart = false;
    this.stopPurge = true;
  }

  calculateFileCountAndSize(): void {
    const onlyCSVList = this.fileList.filter(obj => obj.csv_file);
    this.totalItems = onlyCSVList.length;
    this.sizeCount = this.formatBytes(lodashSumBy(onlyCSVList, 'file_size'), 3);
  }

  back(): void {
    this.router.navigate(['/purge' , 'list']);
  }

  open(content: any) {
    this.modal = this.modalService.open(content);
  }

  confirm() {
    this.purgeFile();
    this.modal?.close();
  }

}

