import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
import { get as lodashGet } from 'lodash';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrl: './login-admin.component.scss'
})
export class LoginAdminComponent implements OnInit {

  public username: string | null = "";
  public password: string | null = "";
  public errorMessage: string | null = null;
  public errorForgotpassword = null;
  public resetUsername: string | null | undefined;
  public resetEmail: string | null | undefined;
  public modal: NgbModalRef | undefined;
  public isLoading: boolean = false;
  public recaptchaToken: boolean | null = false;
  public recaptchaSiteKey: string | undefined = environment['recaptChaSiteKey'];
  public max_failed_login_attempted: number | null | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private utilityService: UtilityService
  ) {

  }

  ngOnInit(): void {
    this.initialSetting();
  }

  initialSetting() {
    this.activatedRoute.params.subscribe(params => {
      this.errorMessage = params['msg'] || null;
    });
  }

  recaptChaResolved(event: any) {
    this.recaptchaToken = event;
  }

  login() {
    this.errorMessage = null;

    if (!this.username || !this.password) {
      this.errorMessage = 'Please complete all information.';
      return;
    }

    this.authenticationService
      .onLogin(this.username, this.password)
      .subscribe({
        next: (res) => {
          const token = lodashGet(res, 'token');
          if (token) {
            this.authenticationService.setCurrentUser(res);
            this.router.navigate(['']);
          } else {
            this.errorMessage = 'Try again';
          }
        },
        error: (err) => {
          const errorString = this.utilityService.getErrorString(err.error);
          if (err && err.status == 400) {
            this.errorMessage = errorString;
          } else {
            this.errorMessage = 'Try again';
          }
        }
      });
  }

  forgotPassword(fgPassword: any) {
    this.modal = this.modalService.open(fgPassword);
    this.resetUsername = null;
    this.resetEmail = null;
    this.recaptchaToken = null;
    this.errorForgotpassword = null;
  }

  close() {
    this.resetUsername = null;
    this.recaptchaToken = null;
    this.resetEmail = null;
    this.modal?.close();
    this.isLoading = false;
  }

  resetPassword(token?: any) {
    if(this.recaptchaToken && this.resetUsername && this.resetEmail) {
      const data = {
        username: this.resetUsername,
        recaptchaToken : this.recaptchaToken,
        email_username : this.resetEmail,
      };
      this.isLoading = true;
      this.apiService
        .forgetPassword(data)
        .subscribe({
          next: (res) => {
            this.notificationService.showSuccess(res);
            this.errorMessage = typeof res === 'string' ? res : null;
            this.close();
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this.errorForgotpassword = err.error;
            this.notificationService.showNotification(err);
            this.recaptchaToken = null;
          }
        });
    }
  }

}
