import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject, first, map, of, takeUntil, zip } from "rxjs";
import { DatatableCustomColumnModel, DocumentTypeModel, LanguageType, LookupModel, ResponseMessageCompanyModel, RowActionEventModel } from "../../shared/interfaces";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../service/authentication.service";
import { Router } from "@angular/router";
import { ApiService } from "../../service/api.service";
import { HandleModalService, HandleTranslateService, MasterDataService, UtilitiesService } from "../../shared/services";
import { HttpClientService } from "../../service/http-client.service";
import { environment } from "../../../environments/environment";
import { EmailDashboardModel, PayloadBuyerTaxIdModel, PayloadClearTransactionEmailModel, PayloadDownloadZipFileTransactionEmailModel, PayloadEmailDashboardModel, ResponseMessageBuyerTaxIdModel, ResponseMessageDownloadZipFileTransactionEmailModel, ResponseMessageEmailDashboardModel } from "./email-dashboard.interface";
import moment from "moment";
import { Chart } from "chart.js";
import { EmailDashboardResendEmailComponent } from "./components";
import { DatatablesComponent } from "../../shared/components/datatables/datatables.component";

@Component({
  selector: 'app-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrl: './email-dashboard.component.scss'
})
export class EmailDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public buyerTaxIds: LookupModel[] = [];
  public documentType: DocumentTypeModel[] = [];

  public filterStatus: string = 'sent';
  public dataTableDescription: string | undefined;
  public transactionTotal: number = 0;
  public transactionSent: number = 0;
  public transactionErrors: number = 0;
  public transactionNotSent: number = 0;
  public transactionManuallySent: number = 0;
  private notIncludePurgedTransaction: boolean = true;

  public chartOfSentEmails: Chart<'doughnut'> | undefined;
  @ViewChild('chartOfSentEmails') private chartOfSentEmailsEl: ElementRef | undefined;
  public chartOfErrorsEmails: Chart<'doughnut'> | undefined;
  @ViewChild('chartOfErrorsEmails') private chartOfErrorsEmailsEl: ElementRef | undefined
  public chartOfNotSentEmails: Chart<'doughnut'> | undefined;
  @ViewChild('chartOfNotSentEmails') private chartOfNotSentEmailsEl: ElementRef | undefined;
  public chartOfManuallySentEmails: Chart<'doughnut'> | undefined;
  @ViewChild('chartOfManuallySentEmails') private chartOfManuallySentEmailsEl: ElementRef | undefined;

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: EmailDashboardModel[] = [];
  public selectedRows: EmailDashboardModel[] = [];
  public columns: DatatableCustomColumnModel[] = [];

  private defaultColumns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'documentDate',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-DATE',
      name: 'document_date',
      type: 'date',
      format: 'DD/MM/YYYY',
      width: '125px',
      minWidth: '125px',
      defaultValue: '-'
    },
    {
      id: 'documentNumber',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER',
      name: 'document_number',
      type: 'string',
      width: '145px',
      minWidth: '145px'
    },
    {
      id: 'email',
      label: 'NEW-TRANSLATE.COMMON.EMAIL',
      name: 'receiver_email',
      type: 'string',
      width: '350px',
      minWidth: '350px'
    },
    {
      id: 'status',
      label: 'NEW-TRANSLATE.COMMON.STATUS',
      name: 'status',
      type: 'dot-color',
      width: '120px',
      minWidth: '120px',
      multiConditions: [
        { value: '#4BC0C0', key: 'sent' },
        { value: '#FFCD56', key: 'not_sent' },
        { value: '#FFCD56', key: 'clear_not_sent' },
        { value: '#FF6384', key: 'failed' },
        { value: '#FF6384', key: 'clear_error' }
      ],
    },
    {
      id: 'datetimeDownload',
      label: 'NEW-TRANSLATE.COMMON.DATE-TIME-DOWNLOAD',
      name: 'downloaded_datetime',
      type: 'date',
      format: 'DD/MM/YYYY HH.mm',
      width: '180px',
      minWidth: '180px',
      defaultValue: '-'
    },
    {
      id: 'sendEmail',
      label: 'NEW-TRANSLATE.COMMON.SEND-EMAIL',
      name: 'resend-email',
      type: 'button',
      format: 'resend-email',
      width: '80px',
      minWidth: '80px'
    }
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    buyer_tax_id: new FormControl(null),
    rd_document_type: new FormControl(null),
    docu_date_period: new FormControl(undefined, [ Validators.required ])
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private handleTranslateService: HandleTranslateService,
    private masterDataService: MasterDataService
  ) {
    this.subscribeToServices();
    this.checkPermission();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chartOfSentEmails?.destroy();
    this.chartOfErrorsEmails?.destroy();
    this.chartOfNotSentEmails?.destroy();
    this.chartOfManuallySentEmails?.destroy();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isEmailDashboardPermission()) {
      this.router.navigate(['/']);
    }
  }

  public isViewer(): boolean {
    return this.authenticationService.isViewerImpAndHelp() || this.authenticationService.isViewerOperation();
  }

  public isIt(): boolean {
    return this.authenticationService.isIT();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBuyerTaxId(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, buyerTaxIds, documentType ]) => {
        this.documentType = documentType;
        this.companies = companies;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
        } else {
          this.companies.unshift(this.allSelect);
        }
        this.buyerTaxIds = buyerTaxIds;
        this.buyerTaxIds.unshift(this.allSelect);
        this.setPeriodDocumentDate();
        this.loadEmailDashboard();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  public setPeriodDocumentDate(): void {
    this.form.controls['docu_date_period'].setValue([ moment(this.currentDate).subtract(60, 'days').toDate(), this.currentDate ]);
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.tax_id }
          });
        })
      );
  }

  private loadBuyerTaxId(): Observable<LookupModel[]> {
    const params: PayloadBuyerTaxIdModel = {
      email_report_status: this.filterStatus,
      fields: 'id,buyer_tax_id'
    };
    return this.httpClientService.get(`${ environment.apiURL }/api/tax_data/`, params)
      .pipe(
        first(),
        map(res => {
          const newRes = res as ResponseMessageBuyerTaxIdModel;
          return [ ...new Set(newRes.results.map(x => x.buyer_tax_id)) ].filter(x => x).map(x => ({
            id: x,
            name_th: x,
            name_en: x
          }));
        })
      );
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  private loadEmailDashboard(): void {
    this.rows = [];
    this.selectedRows = [];
    this.columns = this.filterColumn(this.filterStatus);
    this.dataTableDescription = this.getDataTableDescrition(this.filterStatus);
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const params: PayloadEmailDashboardModel = {
      status: this.filterStatus,
      page: this.pages,
      page_size: this.limitRow,
      not_include_purged_transaction: this.notIncludePurgedTransaction,
      buyer_tax_id: formValue['buyer_tax_id'] ?? '',
      rd_document_type: formValue['rd_document_type'] ?? '',
      seller_tax_id: formValue['corporate_id'] ?? '',
      header_issue_gte: moment(formValue['docu_date_period'][0]).format('YYYY-MM-DD'),
      header_issue_lte: moment(formValue['docu_date_period'][1]).format('YYYY-MM-DD')
    };
    if (!this.isViewer()) {
      delete params.seller_tax_id;
    }
    this.httpClientService
      .get(`${ environment.apiURL }/api/email_report/`, params)
      .pipe(
        first(),
        map(res => {
          const newRes = res as ResponseMessageEmailDashboardModel;
          const newResults = [ ...newRes.results ].map(x => {
                  const value = Object.assign({}, x);
                        value.downloaded_datetime = value.downloaded_datetime ? moment(value.downloaded_datetime).utcOffset('+07:00').toDate() : null;
                        value.document_date = value.document_date ? moment(value.document_date, 'YYYY-MM-DD').toDate() : null;
                        value.receiver_email = value.receiver_email.split(';').join(', ')
                  return value;
                });
                newRes.results = newResults;
         return newRes;
        })
      )
      .subscribe({
        next: (res) => {
          this.transactionTotal = res.transactions_sent + res.transactions_failed + res.transactions_not_sent + res.transactions_clear;
          this.transactionSent = res.transactions_sent;
          this.transactionErrors = res.transactions_failed;
          this.transactionNotSent = res.transactions_not_sent;
          this.transactionManuallySent = res.transactions_clear;
          this.collectionSize = res.count;
          this.rows = res.results ?? [];
          this.isLoadingRows = false;
          this.settingChartOfSentEmails();
          this.settingChartOfErrorsEmails();
          this.settingChartOfNotSentEmails();
          this.settingChartOfManuallySentEmails();
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private settingChartOfSentEmails(): void {
    const data = [ this.transactionSent, this.transactionErrors, this.transactionNotSent ];
    const chartOfSentEmailsEl = this.chartOfSentEmailsEl
    if (chartOfSentEmailsEl) {
      if (!this.chartOfSentEmails) {
        this.chartOfSentEmails = new Chart(chartOfSentEmailsEl.nativeElement, {
          type: 'doughnut',
          data: {
            datasets: [{
              label: '# of Votes',
              data: data,
              backgroundColor: [
                '#4BC0C0',
                '#6F6E6E',
                '#6F6E6E'
              ],
              borderColor: [
                '#ededed',
                '#ededed',
                '#ededed',
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }
        });
      } else {
        this.chartOfSentEmails.data.datasets[0].data = data;
        this.chartOfSentEmails.update();
      }
    }
  }

  private settingChartOfErrorsEmails(): void {
    const data = [ this.transactionSent, this.transactionErrors, this.transactionNotSent ];
    const chartOfErrorsEmailsEl = this.chartOfErrorsEmailsEl;
    if (chartOfErrorsEmailsEl) {
      if (!this.chartOfErrorsEmails) {
        this.chartOfErrorsEmails = new Chart(chartOfErrorsEmailsEl.nativeElement, {
          type: 'doughnut',
          data: {
            datasets: [{
              label: '# of Votes',
              data: data,
              backgroundColor: [
                '#6F6E6E',
                '#FF6384',
                '#6F6E6E'
              ],
              borderColor: [
                '#ededed',
                '#ededed',
                '#ededed',
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }
        });
      } else {
        this.chartOfErrorsEmails.data.datasets[0].data = data;
        this.chartOfErrorsEmails.update();
      }
    }
  }

  private settingChartOfNotSentEmails(): void {
    const data = [ this.transactionSent, this.transactionErrors, this.transactionNotSent ];
    const chartOfNotSentEmailsEl = this.chartOfNotSentEmailsEl;
    if (chartOfNotSentEmailsEl) {
      if (!this.chartOfNotSentEmails) {
        this.chartOfNotSentEmails = new Chart(chartOfNotSentEmailsEl.nativeElement, {
          type: 'doughnut',
          data: {
            datasets: [{
              label: '# of Votes',
              data: data,
              backgroundColor: [
                '#6F6E6E',
                '#6F6E6E',
                '#FFCD56'
              ],
              borderColor: [
                '#ededed',
                '#ededed',
                '#ededed',
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }
        });
      } else {
        this.chartOfNotSentEmails.data.datasets[0].data = data;
        this.chartOfNotSentEmails.update();
      }
    }
  }

  private settingChartOfManuallySentEmails(): void {
    const data = [ this.transactionManuallySent || 1, 0 ];
    const chartOfManuallySentEmailsEl = this.chartOfManuallySentEmailsEl;
    if (chartOfManuallySentEmailsEl) {
      if (!this.chartOfManuallySentEmails) {
        this.chartOfManuallySentEmails = new Chart(chartOfManuallySentEmailsEl.nativeElement, {
          type: 'doughnut',
          data: {
            datasets: [{
              label: '# of Votes',
              data: data,
              backgroundColor: [
                '#CBA1D2',
                '#6F6E6E',
              ],
              borderColor: [
                '#ededed',
                '#ededed'
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }
        });
      } else {
        this.chartOfManuallySentEmails.data.datasets[0].data = data;
        this.chartOfManuallySentEmails.update();
      }
    }
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadEmailDashboard();
  }

  public onClear(): void {
    const defaultCorportateId = this.companies.length === 1 ? this.companies[0].id : null;
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(defaultCorportateId);
          formControl['rd_document_type'].setValue(null);
          formControl['buyer_tax_id'].setValue(null);
    this.setPeriodDocumentDate();
    this.pages = 1;
    this.submitted = false;
    this.dataTableDescription = undefined;
    this.loadEmailDashboard();
  }

  public pageChanged(): void {
    this.loadEmailDashboard();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadEmailDashboard();
  }

  public onFilter(status: string): void {
    if (this.isLoading || this.isLoadingOther || this.isLoadingRows || this.form.invalid) return;
    this.filterStatus = status;
    this.onClear();
  }

  private getDataTableDescrition(type: string): string | undefined {
    if (type === 'sent') return 'NEW-TRANSLATE.COMMON.SENT';
    if (type === 'failed') return 'NEW-TRANSLATE.COMMON.ERRORS';
    if (type === 'not_sent') return 'NEW-TRANSLATE.COMMON.NOT-SENT';
    if (type === 'clear') return 'NEW-TRANSLATE.COMMON.MANUALLY-SENT';
    return;
  }

  private filterColumn(type: string): DatatableCustomColumnModel[] {
    let filterColumn = [];
    if (this.isIt() || this.isViewer()) {
      filterColumn.push('checkbox');
    }
    if (type === 'sent') {
      filterColumn = [ ...filterColumn, ...[
        'datetimeDownload',
        'sendEmail'
      ]];
      return this.utilitiesService.filterArrayByKeyValue(this.defaultColumns, 'id', filterColumn);
    }
    if (type === 'failed') {
      return this.utilitiesService.filterArrayByKeyValue(this.defaultColumns, 'id', filterColumn);
    }
    if (type === 'not_sent') {
      filterColumn = [ ...filterColumn, ...[
        'email',
        'datetimeDownload'
      ]];
      return this.utilitiesService.filterArrayByKeyValue(this.defaultColumns, 'id', filterColumn);
    }
    if (type === 'clear') {
      filterColumn = [ ...filterColumn, ...[
        'sendEmail'
      ]];
      return this.utilitiesService.filterArrayByKeyValue(this.defaultColumns, 'id', filterColumn);
    }
    return this.defaultColumns;
  }

  public onDownloadZipFile(): void {
    this.isLoadingOther = true;
    const selectedRows = [ ...this.selectedRows ].filter(x => !x.tax_data_is_deleted && !x.tax_data_is_deleted_by_user);
    if (selectedRows && selectedRows.length > 0) {
      const payload: PayloadDownloadZipFileTransactionEmailModel = {
        zip_file_name: this.filterStatus === 'clear' ? 'manually-sent' : this.filterStatus ,
        email_report_id_list: [ ...new Set(selectedRows.map(x => x.id)) ]
      }
      this.httpClientService
        .post(`${ environment.apiURL }/api/get_tax_data_zip_file_url_email_report/`, payload)
        .pipe(
          map(x => x as ResponseMessageDownloadZipFileTransactionEmailModel)
        )
        .subscribe({
          next: (res) => {
            if (res.zip_file) {
              window.location.assign(res.zip_file);
            } else {
              this.handleModalService
                .errorModal({
                  texts: [ { text: `NEW-TRANSLATE.MODAL-TEXT.THERE-IS-NO-FILE-TO-DOWNLOAD` } ],
                  cancelButton: {
                    show: true,
                    id: 'closeModalButton',
                    class: 'btn-dv btn-wide',
                    name: 'NEW-TRANSLATE.COMMON.CLOSE'
                  }
                });
            }
            this.loadEmailDashboard();
            this.isLoadingOther = false;
          },
          error: (err) => {
            console.error(err);
            this.isLoadingOther = false;
            if (!this.handleModalService.hasModal('failedModal')) {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        });
    } else {
      this.handleModalService
        .errorModal({
          texts: [ { text: `NEW-TRANSLATE.MODAL-TEXT.NO-DOCUMENT-INFORMATION-AVAILABLE-AS-IT-HAS-BEEN-PURGED` } ],
          cancelButton: {
            show: true,
            id: 'closeModalButton',
            class: 'btn-dv btn-wide',
            name: 'NEW-TRANSLATE.COMMON.CLOSE'
          }
        });
        this.isLoadingOther = false;
    }
  }

  public onClearTransaction(): void {
    this.isLoadingOther = true;
    const payload: PayloadClearTransactionEmailModel = {
      update_data: this.filterStatus === 'failed' ? { status: 'clear_error' } : { status: 'clear_not_sent' },
      email_report_id_list: [ ...this.selectedRows.map(x => x.id) ]
    };
    this.httpClientService
      .post(`${ environment.apiURL }/api/email_report/bulk_update_email_report/`, payload)
      .pipe()
      .subscribe({
        next: () => {
          this.handleModalService.successModal({
            title: 'NEW-TRANSLATE.MODAL-TEXT.SUCCESSFULLY',
            cancelButton: {
              id: 'closeModalButton',
              show: true,
              name: 'NEW-TRANSLATE.COMMON.CLOSE',
              class: 'btn-dv btn-wide'
            }
          });
          this.loadEmailDashboard();
          this.isLoadingOther = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onRowActionEvent(event: RowActionEventModel): void {
    if (event.action === 'resend-email') {
      this.showResendEmailModal(event.row)
    }
  }

  private showResendEmailModal(row: EmailDashboardModel): void {
    const modalEl = this.handleModalService
      .openModal({ 
        componentRef: EmailDashboardResendEmailComponent,
        id: 'resendEmailfModal',
        options: {
          windowClass: 'resend-email-modal',
          centered: true,
          autoCloseRoutingChange: true,
          backdrop: 'static',
          keyboard: false
        },
        value: {
          tax_data_id: row.tax_data,
          email_dashboard_id: row.id
        }
      });
    const modalComponent = (modalEl.componentInstance as EmailDashboardResendEmailComponent);
          modalComponent
            .onSubmitEvent
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                modalComponent.isLoading = true;
                this.httpClientService
                  .post(`${ environment.apiURL }/api/resend_email/`, res)
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      modalComponent.isLoading = false;
                      modalComponent.onNextStep();
                      this.loadEmailDashboard();
                    },
                    error: (err) => {
                      if (err?.status === 400 && err.error) {
                        if (typeof err.error === 'string') {
                          modalComponent.errorMessage = err.error;
                        } else {
                          modalComponent.errorMessage = err.error?.message + ' ' + err.error?.failed_emails;
                        }
                      } else {
                        if (!this.handleModalService.hasModal('failedModal')) {
                          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
                          this.handleModalService.connectFailedModal(errorMessage);
                        }
                      }
                      modalComponent.isLoading = false;
                    }
                  });
              }
            );
  }

}
