import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, first, map, of, switchMap, throwError, zip } from 'rxjs';
import { HttpClientService } from '../../service/http-client.service';
import { environment } from '../../../environments/environment';
import { MasterDataService } from './master-data.service';
import { ResponseMessageDocumentTypeMasterDataModel } from '../interfaces';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private settingSubject: Subject<void> = new Subject<void>();
  public readonly setting: Observable<void> = this.settingSubject.asObservable();

  constructor(
    private httpClientService: HttpClientService,
    private masterDataService: MasterDataService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) {
  }

  public loadInitialSetting(): Observable<void> {
    return zip(
      this.authenticationService.loadProfile(),
      this.httpClientService.get(`${ environment.apiURL }/api/company_master`).pipe(map(x => x as ResponseMessageDocumentTypeMasterDataModel))
    )
    .pipe(
      first(),
      switchMap(([ user, documentType ]) => {
        this.authenticationService.setCurrentUser(user);
        this.masterDataService.setDocumentType(documentType.rd_document_type);
        this.settingSubject.next();
        return of(undefined);
      }),
      catchError((err) => {
        this.settingSubject.error(err);
        return throwError(() => err);
      })
    );
  }

}
