import { Injectable } from '@angular/core';
import { Observable, fromEvent, map } from 'rxjs';
import { ExtractBranchModel, LanguageType, TextModalModel } from '../interfaces';
import { FormGroup, ValidatorFn } from '@angular/forms';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  public convertBlobToJson(blob: Blob): Observable<any> {
    const reader = new FileReader();
    const load$ = fromEvent(reader, 'load').pipe(
      map(() => JSON.parse(reader.result as string))
    );
    reader.readAsText(blob);
    return load$;
  }

  public transformErrorsToTextModal(errors: any): TextModalModel[] {
    let result: TextModalModel[] = [];
    if (errors) {
      if (Array.isArray(errors)) {
        result = [ ...this.transformErrorsArrayToTextModal(errors) ];
      } else if (typeof errors === 'object' && !Array.isArray(errors)) {
        result = [ ...this.transformErrorsObjectToTextModal(errors) ]
      } else if (typeof errors === 'string') {
        result.push({ text: errors });
      }
    }
    return result;
  }

  public transformErrorsArrayToTextModal(errors: any[]): TextModalModel[] {
    let result: TextModalModel[] = [];
    errors.forEach(x => {
      if (Array.isArray(x)) {
        result = [ ...result, ...this.transformErrorsArrayToTextModal(x) ];
      } else if (!Array.isArray(x) && typeof x === 'object') {
        result = [ ...result, ...this.transformErrorsObjectToTextModal(x) ];
      } else if (typeof x === 'string' || typeof x === 'number') {
        result.push({ text: x.toString() });
      }
    });
    return result;
  }

  public transformErrorsObjectToTextModal(errors: Object): TextModalModel[] {
    const result: TextModalModel[] = [];
    const processEntry = (parentKey: string, value: any) => {
      if (Array.isArray(value)) {
        value.forEach((message, index, array) => {
          if (typeof message === 'string') {
            result.push({ text: `${parentKey}: ${message}`, margin: (index + 1) !== array.length ? '0' : undefined });
          } else {
            const nestedMessages = this.transformErrorsObjectToTextModal(message);
            nestedMessages.forEach(nestedMessage =>
                result.push({ text: `${parentKey}: ${nestedMessage.text}`, margin: nestedMessage.margin })
            );
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([key, nestedValue]) => processEntry(`${parentKey}-${key}`, nestedValue));
      } else if (typeof value === 'string') {
        result.push({ text: `${parentKey}: ${value}` });
      }
    };
    Object.entries(errors).forEach(([parentKey, value]) => processEntry(parentKey, value));
    return result;
  }

  public insertUniqueIdToArrayForCheckboxTable(rows: any[]): any[] {
    const newRows = Object.assign([] as any[], rows).map((x, i) => {
      let newValue = Object.assign({}, x);
      if(!newValue['id']) {
        newValue['id'] = i;
      }
      return newValue;
    })
    return newRows;
  }

  public clearValidatorsAndReset(form: FormGroup, fields: string[]): void {
    fields.forEach(field => {
      const control = form.controls[field];
            control.clearValidators();
            control.reset();
            control.updateValueAndValidity();
    });
  }

  public manageFormState(form: FormGroup, fields: { field: string, validators?: ValidatorFn[] | null, value?: any }[], shouldClear: boolean = false): void {
    fields.forEach(field => {
      const control = form.controls[field.field];
      if (shouldClear) {
        control.clearValidators();
        control.reset();
      } else {
        control.setValidators(field.validators ? field.validators : []);
      }
      control.setValue(field.value !== null && field !== undefined ? field.value : undefined);
      control.updateValueAndValidity();
    });
  }

  public filterArrayByKeyValue(arr: any[], key: string, values: string[]): any[] {
    return [ ...arr ].filter(x => !values.includes(x[key]));
  }

  public sortArrayForTranslate(language: LanguageType, list: any[], toggle: [ string, string]): any[] {
    const key = language === 'th' ? toggle[0] : toggle[1];
    return [...list].sort((a, b) => a[key].localeCompare(b[key]));
  }

  public extractBranch(code: string): ExtractBranchModel | undefined {
    const strSplit =  code.split('/');
    if (strSplit.length === 3) return { internal_branch_code: strSplit[0], branch_tax_id: strSplit[1], branch_name: strSplit[2] };
    return;
  }
  
  public createQueryParams(params: any): string {
    return Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${key}=${params[key]}`)
      .join('&');
  }

  public setDateHours(date: Date, hours: number, minutes: number, seconds: number, milisecond: number): Date {
    const newDate = new Date(date);
          newDate.setHours(hours, minutes, seconds, milisecond);
    return newDate;
  }

  public transformDateForPayload(date: Date, timezone: string): string {
    return moment(date).utcOffset(timezone).format('YYYY-MM-DDTHH:mm:ssZ')
  }

  public downloadFile(blob: Blob, filename: string): void {
    const navigator = window.navigator as any;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
    }
  }

  public toggleLoadingCell(targetEl: HTMLElement | null | undefined, loadingEl?: HTMLElement | null | undefined ): void {
    targetEl?.classList.toggle('d-none');
    loadingEl?.classList.toggle('d-none');
  }
 
}
