import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DatatableCustomColumnModel, LanguageType, RowActionEventModel, SortColumnEventModel, SortType } from '../../interfaces';
import { TableRowSelectEvent, Table } from 'primeng/table';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { HandleTranslateService } from '../../services';
import { SortMeta } from 'primeng/api';

@Component({
  selector: 'app-datatables',
  templateUrl: './datatables.component.html',
  styleUrl: './datatables.component.scss'
})
export class DatatablesComponent implements OnDestroy {

  @ViewChild('datables') private datatablesElement: Table | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  @Input() public title: string | undefined;
  @Input() public tableId: string | undefined;
  @Input() public scrollable: boolean = true;
  @Input() public dataKey: string = 'id';
  @Input() public blockRenderingCheckboxKey: string | undefined;
  @Input() public blockRenderingCheckboxBoolean: boolean = true;
  @Input() public customSort: boolean = true;

  @Input() public pages: number = 0;
  @Input() public limitRow: number = 0;
  @Input() public collectionSize: number = 0;

  @Input() public isLoadingRows: boolean = true;
  @Input() public isLoadingOther: boolean = false;

  @Input() public columns: DatatableCustomColumnModel[] = [];

  @Input() public rows: any[] = [];
  
  public selectedRowsValue: any[] = [];
  @Input() get selectedRows() {
    return this.selectedRowsValue;
  };
  @Output() selectedRowsChange = new EventEmitter();

  @Output() onRowActionEvent: EventEmitter<RowActionEventModel> = new EventEmitter<RowActionEventModel>();
  @Output() onSortEvent: EventEmitter<SortColumnEventModel> = new EventEmitter<SortColumnEventModel>


  public sortByValue: string | undefined;
  public sortModeValue: SortType | undefined;

  @Input() public get sortBy() {
    return this.sortByValue;
  }
  @Input() public get sortMode() {
    return this.sortModeValue;
  }
  @Output() sortByChange = new EventEmitter();
  @Output() sortModeChange = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private handleTranslateService: HandleTranslateService
  ) {
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }
  
  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  set selectedRows(value) {
    this.selectedRowsValue = value;
    this.selectedRowsChange.emit(value);
  }

  public rowSelectable = (event: TableRowSelectEvent) => {
    if (this.blockRenderingCheckboxKey) return event.data[this.blockRenderingCheckboxKey] !== this.blockRenderingCheckboxBoolean;
    return true;
  }

  public onRowAction(rowEvent: RowActionEventModel) {
    this.onRowActionEvent.emit(rowEvent);
  }

  public onVerify(event: CheckboxChangeEvent, rowEvent: RowActionEventModel) {
    this.changeDetectorRef.detectChanges();
    rowEvent.row[rowEvent.action] = !event.checked;
    this.onRowActionEvent.emit(rowEvent);
  }

  public onDownloadTotalDocument(rowEvent: RowActionEventModel) {
    this.onRowActionEvent.emit(rowEvent);
  }

  public sortRows(event: SortMeta) {
    if (event.field && event.order) {
      if (this.sortMode === 'desc') {
        this.sortBy = undefined;
        this.sortMode = undefined;
        this.onSortEvent.emit({
          sort_by: this.sortBy,
          sort_mode: this.sortMode
        });
        this.reset();
      } else {
        this.sortBy = event.field;
        this.sortMode = event.order === 1 ? 'asc' : 'desc';
        this.onSortEvent.emit({
          sort_by: this.sortBy,
          sort_mode: this.sortMode
        });
      }
    }
  }

  public set sortBy(value) {
    this.sortByValue = value;
    this.sortByChange.emit(value);
  }

  public set sortMode(value) {
    this.sortModeValue = value;
    this.sortModeChange.emit(value);
  }

  public reset(): void {
    this.datatablesElement?.reset();
  }

}
