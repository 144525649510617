import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { MessageModule } from 'primeng/message';

import { PurgeRoutingModule } from './purge-routing.module';
import { PurgeListComponent } from './purge-list/purge-list.component';
import { PurgeResultComponent } from './purge-result/purge-result.component';
import { PurgeStorageComponent } from './purge-storage/purge-storage.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    PurgeListComponent,
    PurgeResultComponent,
    PurgeStorageComponent
  ],
  imports: [
    CommonModule,
    PurgeRoutingModule,
    DropdownModule,
    FormsModule,
    NgbModule,
    CoreModule,
    CheckboxModule,
    TranslateModule,
    CalendarModule,
    FontAwesomeModule
  ],
  providers: [
    MessageModule
  ]
})
export class PurgeModule { }
