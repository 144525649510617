<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.EMAIL-DASHBOARD.TITLE' | translate }}</h3>
      <h4>
        {{ 'NEW-TRANSLATE.COMMON.TOTAL' | translate }}: @if (!isLoading) { {{ transactionTotal | number: '0.0-0' }} } @else { <fa-icon class="spinner-border" [icon]="['fas','spinner']"></fa-icon> } {{ 'NEW-TRANSLATE.COMMON.TRANSACTION' | translate }}
      </h4>
    </div>
  </div>
</div>

<div class="email-dashboard-container">
  <div class="row g-2 mb-3">
    <div class="col-12 col-md-6">
      <div class="transaction-card-container">
        <div class="row g-2">
          <div class="col-6 col-sm-3 col-md-6 col-lg-3">
            <div
              class="transaction-card transaction-sent-card"
              [ngClass]="[
                isLoading || isLoadingRows || isLoadingOther ? 'transaction-card-disabled' : '',
                filterStatus === 'sent' ? 'active' : ''
              ]"
              (click)="onFilter('sent')"
            >
              <div class="transaction-card-title pt-2">
                <h5 class="text-center mb-0">{{ 'NEW-TRANSLATE.COMMON.SENT' | translate}}</h5>
              </div>
              <div class="canvas-wrapper d-flex justify-content-center align-items-center">
                <canvas
                  #chartOfSentEmails
                  data-testid="chartOfSentEmails"
                  id="chartOfSentEmails"
                >{{ chartOfSentEmails }}</canvas>
              </div>
              <div class="transaction-info text-center text-break">
                <h6 class="mb-1">
                  @if (isLoading) {
                    <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="sm"></fa-icon>
                  } @else {
                    {{ transactionSent | number: '0.0-0' }}
                  }
                </h6>
                <h6 class="mb-0">{{ 'NEW-TRANSLATE.COMMON.TRANSACTION' | translate }}</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-6 col-lg-3">
            <div
              class="transaction-card transaction-errors-card"
              [ngClass]="[
                isLoading || isLoadingRows || isLoadingOther ? 'transaction-card-disabled' : '',
                filterStatus === 'failed' ? 'active' : ''
              ]"
              (click)="onFilter('failed')"
            >
              <div class="transaction-card-title pt-2">
                <h5 class="text-center mb-0">{{ 'NEW-TRANSLATE.COMMON.ERRORS' | translate }}</h5>
              </div>
              
              <div class="canvas-wrapper d-flex justify-content-center align-items-center">
                <canvas
                  #chartOfErrorsEmails
                  data-testid="chartOfErrorsEmails"
                  id="chartOfErrorsEmails"
                >{{ chartOfErrorsEmails }}</canvas>
              </div>
              <div class="transaction-info text-center text-break">
                <h6 class="mb-1">
                  @if (isLoading) {
                    <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="sm"></fa-icon>
                  } @else {
                    {{ transactionErrors | number: '0.0-0' }}
                  }
                </h6>
                <h6 class="mb-0">{{ 'NEW-TRANSLATE.COMMON.TRANSACTION' | translate }}</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-6 col-lg-3">
            <div
              class="transaction-card transaction-not-sent-card"
              [ngClass]="[
                isLoading || isLoadingRows || isLoadingOther ? 'transaction-card-disabled' : '',
                filterStatus === 'not_sent' ? 'active' : ''
              ]"
              (click)="onFilter('not_sent')"
            >
              <div class="transaction-card-title pt-2">
                <h5 class="text-center mb-0">{{ 'NEW-TRANSLATE.COMMON.NOT-SENT' | translate }}</h5>
              </div>
              <div class="canvas-wrapper d-flex justify-content-center align-items-center">
                <canvas
                  #chartOfNotSentEmails
                  data-testid="chartOfNotSentEmails"
                  id="chartOfNotSentEmails"
                >{{ chartOfSentEmails }}</canvas>
              </div>
              <div class="transaction-info text-center text-break">
                <h6 class="mb-1">
                  @if (isLoading) {
                    <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="sm"></fa-icon>
                  } @else {
                    {{ transactionNotSent | number: '0.0-0' }}
                  }
                </h6>
                <h6 class="mb-0">{{ 'NEW-TRANSLATE.COMMON.TRANSACTION' | translate }}</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-6 col-lg-3">
            <div
              class="transaction-card transaction-manually-sent-card"
              [ngClass]="[
                isLoading || isLoadingRows || isLoadingOther ? 'transaction-card-disabled' : '',
                filterStatus === 'clear' ? 'active' : ''
              ]"
              (click)="onFilter('clear')"
            >
              <div class="transaction-card-title pt-2">
                <h5 class="text-center mb-0">{{ 'NEW-TRANSLATE.COMMON.MANUALLY-SENT' | translate }}</h5>
              </div>
              <div class="canvas-wrapper d-flex justify-content-center align-items-center">
                <canvas
                  #chartOfManuallySentEmails
                  data-testid="chartOfManuallySentEmails"
                  id="chartOfManuallySentEmails"
                >{{ chartOfManuallySentEmails }}</canvas>
              </div>
              <div class="transaction-info text-center text-break">
                <h6 class="mb-1">
                  @if (isLoading) {
                    <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="sm"></fa-icon>
                  } @else {
                    {{ transactionManuallySent | number: '0.0-0' }}
                  }
                </h6>
                <h6 class="mb-0">{{ 'NEW-TRANSLATE.COMMON.TRANSACTION' | translate }}</h6>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="criteria-search-container">
        <form [formGroup]="form" class="form-wrapper">
          <app-filter-header
            [loading]="isLoading || isLoadingRows || isLoadingOther"
            (submit)="onSearch()"
            (clear)="onClear()"
          />
          <div class="row g-2">
            @if (isViewer()) {
              <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                <div
                  class="dropdown-label-group"
                  [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
                >
                  <p-dropdown
                    class="dv-dropdown"
                    formControlName="corporate_id"
                    selectId="companySelect"
                    [options]="companies"
                    optionValue="id"
                    [loading]="isLoading || isLoadingRows || isLoadingOther"
                    [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
                  >
                    <ng-template let-item pTemplate="selectedItem">
                      <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                    </ng-template>
                  </p-dropdown>
                  <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
                </div>
              </div>
            }
            <div class="col-12 col-sm-6 col-md-12 col-lg-6">
              <div
                class="dropdown-label-group"
                [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_tax_id'].errors }"
              >
                <p-dropdown
                  class="dv-dropdown"
                  formControlName="buyer_tax_id"
                  selectId="buyerTaxIdSelect"
                  [options]="buyerTaxIds"
                  optionValue="id"
                  [loading]="isLoading || isLoadingRows || isLoadingOther"
                  [placeholder]="'NEW-TRANSLATE.COMMON.BUYER-TAX-ID' | translate"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                </p-dropdown>
                <label for="buyerTaxIdSelect">{{ 'NEW-TRANSLATE.COMMON.BUYER-TAX-ID' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-6">
              <app-date-range-picker
                formControlName="docu_date_period"
                label="NEW-TRANSLATE.COMMON.DOCUMENT-DATE"
                [maxRange]="93"
                [ngClass]="{ 'is-invalid': submitted && form.controls['docu_date_period'].errors }"
                [isLoading]="isLoading || isLoadingRows || isLoadingOther"
              ></app-date-range-picker>
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-6">
              <div
                class="dropdown-label-group"
                [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
              >
                <p-dropdown
                  class="dv-dropdown"
                  formControlName="rd_document_type"
                  [loading]="isLoading || isLoadingRows || isLoadingOther"
                  [options]="documentType"
                  optionValue="type"
                  [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                  selectId="documentTypeSelect"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                </p-dropdown>
                <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    >
      @if (!isViewer() && !isIt()) {
        <div class="col-auto d-flex gap-2 mt-3 mt-lg-0">
          @if (filterStatus === 'failed' || filterStatus === 'not_sent') {
            <button
              class="btn btn-dv"
              data-testid="clearPdfA3XmlButton"
              id="clearPdfA3XmlButton"
              [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
              [title]="'NEW-TRANSLATE.EMAIL-DASHBOARD.MANUALLY-SENT-PDF-A3-OR-XML' | translate"
              (click)="onClearTransaction()"
            >
              @if (isLoadingOther) {
                <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
              }
              {{ 'NEW-TRANSLATE.EMAIL-DASHBOARD.MANUALLY-SENT-PDF-A3-OR-XML' | translate }}
            </button>
          }
          <button
            class="btn btn-dv"
            data-testid="downloadPdfA3XmlButton"
            id="downloadPdfA3XmlButton"
            [disabled]="
              !rows
              || rows.length === 0
              || !selectedRows
              || selectedRows.length === 0
              || isLoadingRows
              || isLoadingOther
              || isLoading
            "
            [title]="'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-PDF-A3-OR-XML' | translate"
            (click)="onDownloadZipFile()"
          >
            @if (isLoadingOther) {
              <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
            }
            {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-PDF-A3-OR-XML' | translate }}
          </button>
        </div>
      }
     
    </app-custom-pagination>
    <div class="datatble-description-conrainer pt-3">
      @if (!isLoading && !isLoadingRows) {
        @if (dataTableDescription) {
          <div contentAnimate>
            <h5 class="mb-0">{{ dataTableDescription | translate }}</h5>
          </div>
        }
      } @else {
        <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="sm"></fa-icon>
      }
    </div>
   
    <app-datatables
      #datatables
      tableId="emailDashboardDatatable"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [columns]="columns"
      [rows]="rows"
      [pages]="pages"
      [limitRow]="limitRow"
      [(selectedRows)]="selectedRows"
      [collectionSize]="collectionSize"
      (onRowActionEvent)="onRowActionEvent($event)"
    />
  </div>
</div>