import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { API_CONSTANT } from './api.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurgeService {

  constructor(
    private httpClientService: HttpClientService
  ) {
  }

  getCSVList(params?: any): Observable<Object> {
    return this.httpClientService.get(API_CONSTANT.upload_csv, params);
  }

  patchTaxImport(data: any): Observable<Object> {
    return this.httpClientService.patch(API_CONSTANT.patch_tax_import, data);
  }

  deleteTaxImport(id: any): Observable<Object> {
    return this.httpClientService.delete(API_CONSTANT.upload_csv + id + '/');
  }

  downloadCSVFile(url: any): Observable<Object> {
    return this.httpClientService.getBlob(url);
  }

}
