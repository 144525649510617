import { Component, OnDestroy } from '@angular/core';
import { FontawesomeService, HandleModalService, HandleTranslateService } from './shared/services';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fontawesomeService: FontawesomeService,
    private primengConfig: PrimeNGConfig,
    private handleTranslateService: HandleTranslateService,
    private handleModalService: HandleModalService
  ) {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initialSetting(): void {
    this.handleModalService.startSubscriptionModals();
    this.fontawesomeService.initialIcon();
    this.handleTranslateService
      .language
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.handleTranslateService.get('NEW-TRANSLATE.PRIME-NG-CALENDAR').subscribe(x => this.primengConfig.setTranslation(x));
      })
  }

}
