import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';
import { get as lodashGet } from 'lodash';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
            .pipe(
              catchError(
                (err: HttpErrorResponse) => {
                  const errorDetail = lodashGet(err, 'error.detail');
                  const errorMessages = [
                    'Signature has expired.',
                    'Error decoding signature.',
                    'Invalid signature.',
                    'User account is disabled.',
                    'บัญชีผู้ใช้ถูกระงับ',
                    'Given token not valid for any token type',
                    'Authentication credentials were not provided.'
                  ];
                  if (errorMessages.includes(errorDetail)) {
                    this.authenticationService.onLogout();
                    const customError = { ...err, error: [
                      'NEW-TRANSLATE.ERROR.SORRY-YOUR-TOKEN-EXPIRED',
                      'NEW-TRANSLATE.ERROR.PLEASE-LOGIN-AGAIN'
                    ]};
                    return throwError(() => customError);
                  }
                  return throwError(() => err);
                }
              )
            );
  }

}