import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Observable, BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { LanguageType, LangChangeEventModel } from '../../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class HandleTranslateService implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  private languageSubject: BehaviorSubject<LanguageType> | undefined = undefined;
  public language: Observable<LanguageType> | undefined = undefined;

  constructor(
    private translateService: TranslateService
  ) {
    this.languageSubject = new BehaviorSubject<LanguageType>(this.translateService.defaultLang as LanguageType);
    this.language = this.languageSubject.asObservable();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$),map(x => x as LangChangeEventModel)).subscribe((event) => this.languageSubject?.next(event.lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public translate(text: string): string {
    return this.translateService.instant(text);
  }

  public toggleLanguage(): void {
    const language = this.languageSubject?.value;
    if (language) {
      this.setLanguage(language === 'en' ? 'th' : 'en');
    } else {
      this.setLanguage('th');
    }
  }

  public setLanguage(language: LanguageType): void {
    if (language) {
      this.translateService.use(language);
      localStorage.setItem('lang', language);
    } else {
      this.translateService.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  public setTranslation(language: LanguageType, translate: object): void {
    if (language) {
      this.translateService.setTranslation(language, translate);
    }
  }

  public get(key: string): Observable<any> {
    return this.translateService.get(key).pipe(takeUntil(this.unsubscribe$));
  }

  public currentLanguage(): LanguageType {
    return (this.translateService.currentLang || this.translateService.defaultLang) as LanguageType;
  }

}
