import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DatatableCustomColumnModel, LanguageType, LookupModel, PayloadYearAndMonthModel, ResponseMessageCompanyAndYearModel, ResponseMessageCompanyModel } from "../../shared/interfaces";
import { Observable, Subject, catchError, concatMap, finalize, first, from, map, of, takeUntil, tap, throwError, zip } from "rxjs";
import { MONTHS } from "../../shared/services/shared.constant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../service/authentication.service";
import { HandleModalService, HandleTranslateService, UtilitiesService } from "../../shared/services";
import { ApiService } from "../../service/api.service";
import { Router } from "@angular/router";
import { HttpClientService } from "../../service/http-client.service";
import { FeeEngineService } from "../../service/fee-engine.service";
import { environment } from "../../../environments/environment";
import { FeEngineModel, PayloadFeeEngineModel, ResponseMessageFeeEngineModel } from "./fee-engine.interface";
import { DatatablesComponent } from "../../shared/components/datatables/datatables.component";

@Component({
  selector: 'app-fee-engine',
  templateUrl: './fee-engine.component.html',
  styleUrl: './fee-engine.component.scss'
})
export class FeeEngineComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: FeEngineModel[] = [];
  public selectedRows: FeEngineModel[] = [];
  public blockRenderingCheckboxKey: string = 'can_download_zip';
  public blockRenderingCheckboxBoolean: boolean = false;
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'companyName',
      label: 'NEW-TRANSLATE.COMMON.COMPANY',
      name: 'company_name',
      type: 'string',
      width: '350px',
      minWidth: '350px'
    },
    {
      id: 'numberOfDocuments',
      label: 'NEW-TRANSLATE.COMMON.NUMBER-OF-DOCUMENTS',
      name: 'transaction_volume',
      type: 'number',
      width: '120px',
      minWidth: '120px',
      format: '0.0-0',
      defaultValue: '0'
    },
    {
      id: 'month',
      label: 'NEW-TRANSLATE.COMMON.MONTH',
      name: 'month',
      type: 'month',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'year',
      label: 'NEW-TRANSLATE.COMMON.YEAR',
      name: 'year',
      type: 'string',
      width: '120px',
      minWidth: '120px'
    }
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    month: new FormControl(this.currentDate.getMonth() + 1, [ Validators.required ]),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ])
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private feeEngineService: FeeEngineService
  ) {
    this.checkPermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isFeeEnginePermission()) {
      this.router.navigate(['/']);
    }
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (this.authenticationService.isViewerImpAndHelp()) {
      removeColumns([ 'checkbox' ]);
    }
    this.columns = columns;
  }

  public isViewerOperation(): boolean {
    return this.authenticationService.isViewerOperation();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadYear(),
    )
    .pipe()
    .subscribe({
      next: ([ companies, years ]) => {
        this.years = years;
        this.companies = companies;
        this.companies.unshift(this.allSelect);
        this.loadFeeEngine();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadYear(): Observable<number[]> {
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years);
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.feeEngineService
      .getDropDownCompanyAndYear()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyAndYearModel;
          return [ ...newRes.company ].map(x => {
            return { name_th: x.customer__name, name_en: x.customer__name, id: x.customer__id }
          });
        })
      );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadFeeEngine();
  }

  public onClear(): void {
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(null);
          formControl['month'].setValue(this.currentDate.getMonth() + 1);
          formControl['year'].setValue(this.currentDate.getFullYear());
    this.pages = 1;
    this.submitted = false;
    this.loadFeeEngine();
  }

  public pageChanged(): void {
    this.loadFeeEngine();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadFeeEngine();
  }

  private loadFeeEngine(): void {
    this.rows = [];
    this.selectedRows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const params: PayloadFeeEngineModel = {
      company: formValue['corporate_id'] ?? '',
      year: formValue['year'] ?? '',
      month: formValue['month'] ?? '',
      page: this.pages,
      page_size: this.limitRow
    };
    this.httpClientService
      .get(`${ environment.apiURL }/api/fee_engine/`, params)
      .pipe(
        first(),
        map((res) => {
          const newRes = res as ResponseMessageFeeEngineModel;
          const newResults = this.utilitiesService.insertUniqueIdToArrayForCheckboxTable([ ...newRes.results ]);
                newRes.results = newResults;
          return newRes;
        })
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results;
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onDownloadAllCsv(): void {
    this.isLoadingOther = true;
    const formValue = this.form.value;
    const params: PayloadYearAndMonthModel = {
      year: formValue['year'] ?? '',
      month: formValue['month'] ?? ''
    }
    this.httpClientService
      .getBlob(`${ environment.apiURL }/api/fee_engine/summary_csv/`, params)
      .pipe(
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          const filename = 'all_summary_fee_engine ' + formValue['month'] + '/'+ formValue['year'] + '.csv';
          this.utilitiesService.downloadFile(res, filename);
          this.isLoadingOther = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      });
  }

  public onDownloadCsv(): void {
    this.isLoadingOther = true;
    const formValue = this.form.value;
    from(this.selectedRows)
      .pipe(
        concatMap((item) => {
          const params = {
            company: item.company_id,
            year: item.year,
            month: item.month
          };
          return this.httpClientService
            .getBlob(`${ environment.apiURL }/api/fee_engine/csv/`, params)
            .pipe(
              map(x => x as Blob),
              tap((fileData) => {
                const filename = item.company_name + ' fee_engine ' + formValue['month'] + '/'+ formValue['year'] + '.csv';
                this.utilitiesService.downloadFile(fileData, filename);
              }),
              catchError((error) => {
                console.error('Download error:', error);
                return throwError(() => error);
              })
            )
        }),
        catchError((error) => {
          this.isLoadingOther = false;
          return throwError(() => error);
        }),
        finalize(() => {
          this.isLoadingOther = false;
        })
      )
      .subscribe();
  }

}
