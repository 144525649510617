import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';
import { Observable, Subject, first, map, of, takeUntil, zip } from 'rxjs';
import { BranchModel, DatatableCustomColumnModel, DocumentTypeModel, LanguageType, LookupModel, ResponseMessageBranchModel, ResponseMessageCompanyModel } from '../../shared/interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { HandleModalService, HandleTranslateService, MasterDataService, UtilitiesService } from '../../shared/services';
import { MONTHS } from '../../shared/services/shared.constant';
import { HttpClientService } from '../../service/http-client.service';
import { environment } from '../../../environments/environment';
import { PayloadSubmitToRdManualModel, PayloadSubmitToRdModel, PayloadSubmitToRdReportDownloadExcelModel, PayloadSubmitToRdReportModel, ResponseMessageSubmitToRdReportModel, SubmitToRdReportModel } from './submit-to-rd.interface';
import { DatatablesComponent } from '../../shared/components/datatables/datatables.component';

@Component({
  selector: 'app-submit-to-rd',
  templateUrl: './submit-to-rd.component.html',
  styleUrl: './submit-to-rd.component.scss'
})
export class SubmitToRdComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allBranchSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allCompanySelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All',
    tax_id: undefined
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public branches: LookupModel[] = [];
  private defaultBranches: BranchModel[] = [];
  public documentType: DocumentTypeModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: SubmitToRdReportModel[] = [];
  public selectedRows: SubmitToRdReportModel[] = [];
  public blockRenderingCheckboxKey: string = 'can_submit';
  public blockRenderingCheckboxBoolean: boolean = false;
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'companyName',
      label: 'NEW-TRANSLATE.COMMON.COMPANY-NAME',
      name: 'company_name',
      type: 'string',
      width: '200px',
      minWidth: '200px',
      defaultValue: '-'
    },
    {
      id: 'branchName',
      label: 'NEW-TRANSLATE.COMMON.BRANCH-NAME',
      name: '',
      type: 'join-string',
      width: '300px',
      minWidth: '300px',
      joinString: {
        keys: [
          'internal_branch_code',
          'branch_code',
          'branch_name'
        ],
        separator: '/'
      }
    },
    {
      id: 'documentType',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE',
      name: 'rd_document_type',
      type: 'document-type',
      width: '150px',
      minWidth: '150px'
    },
    {
      id: 'submitToRdNumberOfDocumentType',
      label: 'NEW-TRANSLATE.SUBMIT-TO-RD.NUMBER-OF-DOCUMENT-TYPE',
      name: 'total_document',
      type: 'number',
      width: '120px',
      minWidth: '120px',
      format: '0.0-0'
    },
    {
      id: 'submitToRdVerifiedFromAccount',
      label: 'NEW-TRANSLATE.SUBMIT-TO-RD.VERIFIED-FROM-ACCOUNT',
      name: 'total_verified',
      type: 'number',
      width: '120px',
      minWidth: '120px',
       format: '0.0-0'
    },
    {
      id: 'submitToRd',
      label: 'NEW-TRANSLATE.SUBMIT-TO-RD.SUBMIT-TO-RD',
      name: 'total_sent',
      type: 'number',
      width: '120px',
      minWidth: '120px',
       format: '0.0-0'
    },
    {
      id: 'submitToRdSubmitCompletely',
      label: 'NEW-TRANSLATE.SUBMIT-TO-RD.SUBMIT-COMPLETELY',
      name: 'is_submit',
      type: 'boolean-icon',
      width: '120px',
      minWidth: '120px'
    },
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate: new FormControl(this.allCompanySelect),
    corporate_branch_id: new FormControl(null),
    rd_document_type: new FormControl(null),
    month: new FormControl(this.currentDate.getMonth() + 1, [ Validators.required ]),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ]),
    not_sent_only: new FormControl(false, [ Validators.required ])
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private handleTranslateService: HandleTranslateService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isSubmitToRdPermission()) {
      this.router.navigate(['/']);
    }
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (this.authenticationService.isViewerImpAndHelp()) {
      removeColumns([ 'checkbox' ]);
    } else {
      removeColumns([ 'companyName' ]);
    }
    this.columns = columns;
  }

  public isPermissionDatatableTools(): boolean {
    return !this.authenticationService.isViewerImpAndHelp();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBranch(),
      this.loadYear(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, branches, years, documentType ]) => {
        this.documentType = documentType;
        this.years = years;
        this.companies = companies;
        this.defaultBranches = branches.results;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0];
          this.form.controls['corporate'].setValue(defaultCompany, { emitEvent: false });
          this.filterBranches(defaultCompany);
        } else {
          this.companies.unshift(this.allCompanySelect);
          this.filterBranches();
        }
        this.loadSubmitToRdReport();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadYear(): Observable<number[]> {
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years);
  }

  public filterBranches(company?: LookupModel | null) {
    this.form.controls['corporate_branch_id'].setValue(null);
    this.branches = Object.assign([] as BranchModel[], this.defaultBranches)
                      .filter(x => x.corporate === company?.id || !company)
                      .map(x => {
                        const filterName = x.code + '/' + x.rd_id + '/' + x.name;
                        return { name_th: filterName, name_en: filterName, id: x.id };
                      });
    this.branches.unshift(this.allBranchSelect);          
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id, tax_id: x.tax_id }
          });
        })
      );
  }

  private loadBranch(): Observable<ResponseMessageBranchModel> {
    return this.apiService
        .getBranch()
        .pipe(
          first(),
          map(x => x as ResponseMessageBranchModel)
        );
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.filter(x => x.is_sent_to_rd).map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadSubmitToRdReport();
  }

  public onClear(): void {
    const defaultCorportate = this.companies.length === 1 ? this.companies[0] : null;
    const formControl = this.form.controls;
          formControl['corporate'].setValue(defaultCorportate ?? this.allCompanySelect);
          formControl['corporate_branch_id'].setValue(null);
          formControl['rd_document_type'].setValue(null);
          formControl['month'].setValue(this.currentDate.getMonth() + 1);
          formControl['year'].setValue(this.currentDate.getFullYear());
          formControl['not_sent_only'].setValue(false);
    this.filterBranches(defaultCorportate);
    this.pages = 1;
    this.submitted = false;
    this.loadSubmitToRdReport();
  }

  public pageChanged(): void {
    this.loadSubmitToRdReport();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadSubmitToRdReport();
  }

  private loadSubmitToRdReport(): void {
    this.rows = [];
    this.selectedRows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const payload: PayloadSubmitToRdReportModel = {
      corporate_id: formValue['corporate']?.id ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      year: formValue['year'],
      month: formValue['month'],
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      not_sent_only: formValue['not_sent_only']
    };
    this.httpClientService
      .post(`${ environment.apiURL }/api/submit_to_rd/report?page=${ this.pages }&page_size=${ this.limitRow }`, payload)
      .pipe(
        first(),
        map(res => {
          const newRes = res as ResponseMessageSubmitToRdReportModel;
          const newResults = this.utilitiesService.insertUniqueIdToArrayForCheckboxTable([ ...newRes.results ])
                              .map(y => {
                                const value = { ...y } as SubmitToRdReportModel;
                                      value.can_submit = !value.is_submit && value.total_verified > 0;
                                return value;
                              })
                newRes.results = newResults;
          return newRes;
        })
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results;
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onDownloadExcel(): void {
    if (!this.rows || this.rows.length === 0) return;
    this.isLoadingOther = true;
    const formValue = this.form.value;
    const payload: PayloadSubmitToRdReportDownloadExcelModel = {
      corporate_id: formValue['corporate']?.id ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      year: formValue['year'],
      month: formValue['month'],
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      not_sent_only: formValue['not_sent_only']
    };
    this.httpClientService
      .postBlob(`${ environment.apiURL }/api/submit_to_rd/export`, payload)
      .pipe(
        first(),
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          const filename = 'submit-to-rd_' + (formValue['corporate']?.tax_id ?? '') + '_' + formValue['year'] + '_' + formValue['month'];
          this.utilitiesService.downloadFile(res, filename);
          this.isLoadingOther = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      })
  }

  public onSubmitToRd(): void {
    if (!this.selectedRows || this.selectedRows.length === 0) return;
    this.handleModalService
      .confirmModal({
        title: 'NEW-TRANSLATE.COMMON.CONFIRMATION',
        texts: [ { text: 'NEW-TRANSLATE.MODAL-TEXT.ARE-YOU-SURE-TO-SUBMIT-THIS-DOCUMENT' } ],
        cancelButton: {
          show: true,
          id: 'cancelModalButton',
          name: 'NEW-TRANSLATE.MODAL-TEXT.NO'
        },
        confirmButton: {
          show: true,
          id: 'confirmModalButton',
          name: 'NEW-TRANSLATE.COMMON.YES'
        }
      })
      .result
      .then(
        () => {
          this.isLoadingOther = true;
          const formValue = this.form.value;
          const payload: PayloadSubmitToRdManualModel = {
            year: formValue['year'],
            month: formValue['month'],
            submit_list: this.getSubmitToRdManual()
          };
          this.httpClientService
            .post(`${ environment.apiURL }/api/submit_to_rd/manual`, payload)
            .pipe(first())
            .subscribe({
              next: () => {
                this.handleModalService.successModal({
                  title: 'NEW-TRANSLATE.SUBMIT-TO-RD.SUBMIT-ALREADY',
                  cancelButton: {
                    id: 'closeModalButton',
                    show: true,
                    name: 'NEW-TRANSLATE.COMMON.CLOSE',
                    class: 'btn-dv btn-wide'
                  }
                });
                this.loadSubmitToRdReport();
                this.isLoadingOther = false;
              },
              error: (err) => {
                console.error(err);
                this.isLoadingOther = false;
                if (!this.handleModalService.hasModal('failedModal')) {
                  const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
                  this.handleModalService.connectFailedModal(errorMessage);
                }
              }
            });

        }
      );
  }

  private getSubmitToRdManual(): PayloadSubmitToRdModel[] {
    const newSelected = Object.assign([] as SubmitToRdReportModel[], this.selectedRows)
                          .map(x => {
                            const value = Object.assign({}, x);
                            return {
                              corporate_branch_id: value.corporate_branch__id,
                              rd_document_type: value.rd_document_type
                            }
                          });
    return newSelected;
  }

}
