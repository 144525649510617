import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { ApiService } from '../../service/api.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject, Subscription, filter, fromEvent, takeUntil } from 'rxjs';
import { LanguageType, UserModel } from '../../shared/interfaces';
import { HandleTranslateService } from '../../shared/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  private idleSubscription$: Subscription | undefined;

  public user: UserModel | undefined;

  public language: LanguageType;
  
  public username: string | null  = '';
  public toggleNav: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private idle: Idle,
    private keepalive: Keepalive,
    private handleTranslateService: HandleTranslateService
  ) {
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
    this.authenticationService.user?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.user = x);
    this.watchIdle();
  }

  public onChangeLanguage(language: LanguageType): void {
    this.handleTranslateService.setLanguage(language);
  }

  public isKeyinPermission(): boolean {
    return this.authenticationService.isKeyinPermission();
  }

  public isSettingPermission(): boolean {
    return this.authenticationService.isSettingPermission();
  }

  public isSubmitToRdPermission(): boolean {
    return this.authenticationService.isSubmitToRdPermission();
  }

  public isRevenueDocumentPermission(): boolean {
    return this.authenticationService.isRevenueDocumentPermission();
  }

  public isSalesTaxReportPermission(): boolean {
    return this.authenticationService.isSalesTaxReportPermission();
  }

  public isDocumentSearchPermission(): boolean {
    return this.authenticationService.isDocumentSearchPermission();
  }

  public isRdSummaryPermission(): boolean {
    return this.authenticationService.isRdSummaryPermission();
  }

  public isFeeEnginePermission(): boolean {
    return this.authenticationService.isFeeEnginePermission();
  }

  public isDocumentUploadPermission(): boolean {
    return this.authenticationService.isDocumentUploadPermission();
  }

  public isBulkTaxReportPermission(): boolean {
    return this.authenticationService.isBulkTaxReportPermission();
  }

  public isAnnouncementPermission(): boolean {
    return this.authenticationService.isAnnouncementPermission();
  }

  public isDashboardPermission(): boolean {
    return this.authenticationService.isDashboardPermission();
  }

  public isMonitoringPermission(): boolean {
    return this.authenticationService.isMonitoringPermission();
  }

  public isEmailDashboardPermission(): boolean {
    return this.authenticationService.isEmailDashboardPermission();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  canUserSignDoc() {
    return this.authenticationService.isAccountant();
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  isUIH() {
    return this.authenticationService.isUIH();
  }

  isAccountant() {
    return this.authenticationService.isAccountant();
  }

  isAccountantAdmin() {
    return this.authenticationService.isAccountantAdmin();
  }

  isMaker(){
    return this.authenticationService.isMaker();
  }

  isChecker(){
    return this.authenticationService.isChecker();
  }

  isSuperChecker() {
    return this.authenticationService.isSuperChecker();
  }

  isViewerOperation() {
    return this.authenticationService.isViewerOperation();
  }

  isViewerImpAndHelp() {
    return this.authenticationService.isViewerImpAndHelp();
  }

  isIT() {
    return this.authenticationService.isIT();
  }

  isSCBBCMManageUser() {
    return this.authenticationService.isSuperMaker() || this.authenticationService.isMaker() || this.authenticationService.isSuperChecker()
      || this.authenticationService.isChecker();
  }

  isSCBBCMViewer() {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  isNotSCIUser() {
    return !this.authenticationService.isSCIUser();
  }

  public onLogout(): void {
    this.clearIdle();
    this.authenticationService.onLogout();
  }

  private clearIdle(): void {
    this.idle.stop();
    this.keepalive.stop();
    this.idleSubscription$?.unsubscribe();
  }

  private watchIdle(): void {
    this.watchVisibilityChange();
    this.idle.setIdle(14 * 60);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idleSubscription$ = this.idle
      .onTimeout
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.clearIdle();
        this.handleLogout();
      });
    this.keepalive.interval(30);
    this.idle.watch();
  }

  private handleLogout(): void {
    this.apiService
      .logout()
      .subscribe({
        next: () => {
          console.info('Logout successful');
        },
        error: (err) => {
          console.error('Logout failed', err);
        }
      });
    this.authenticationService.onLogout();
    setTimeout(() => {
      alert(this.handleTranslateService.translate('TEXT.TIME-OUT'));
    }, 900);
  }

  private watchVisibilityChange(): void {
    fromEvent(window, 'visibilitychange')
      .pipe(
        filter(() => document.visibilityState === 'visible'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        if (!this.authenticationService.isLoggedIn()) {
          this.clearIdle();
          this.authenticationService.onLogout();
        }
      });
  }

}
