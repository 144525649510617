import { Component, OnDestroy } from '@angular/core';
import { HandleModalService, SettingService, UtilitiesService } from '../shared/services';
import { Subject, first, takeUntil } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public isLoading: boolean = true;

  constructor(
    private settingService: SettingService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService,
    private authenticationService: AuthenticationService
  ) {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initialSetting(): void {
    this.isLoading = true;
    if (!this.authenticationService.isLoggedIn()) return;
    this.settingService
      .loadInitialSetting()
      .pipe()
      .subscribe({
        next: () => {
          this.isLoading = false;
          console.info('Login successful');
        },
        error: (err) => {
          console.error('Login failed', err);
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

}
