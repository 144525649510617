import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../service/authentication.service";
import { Router } from "@angular/router";
import { ApiService } from "../../service/api.service";
import { HandleModalService, HandleTranslateService, MasterDataService, UtilitiesService } from "../../shared/services";
import { HttpClientService } from "../../service/http-client.service";
import { Observable, Subject, first, map, of, takeUntil, zip } from "rxjs";
import { BranchModel, DatatableCustomColumnModel, DocumentTypeModel, LanguageType, LookupModel, PayloadDownloadFileModel, ResponseMessageBranchModel, ResponseMessageCompanyModel, SortType } from "../../shared/interfaces";
import { environment } from "../../../environments/environment";
import moment from "moment";
import { DocumentSearchModel, PayloadDocumentSearchModel, ResponseMessageDocumentSearchModel } from "./search.interface";
import { DatatablesComponent } from "../../shared/components/datatables/datatables.component";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public branches: LookupModel[] = [];
  private defaultBranches: BranchModel[] = [];
  public documentType: DocumentTypeModel[] = [];

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public sortBy: string | undefined;
  public sortMode: SortType | undefined;
  public rows: DocumentSearchModel[] = [];
  public selectedRows: DocumentSearchModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'documentNumber',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER',
      name: 'header_invoice_number',
      type: 'string',
      width: '180px',
      minWidth: '180px',
      sort: true
    },
    {
      id: 'documentDate',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-DATE',
      name: 'header_issue_dt',
      type: 'date',
      width: '180px',
      minWidth: '180px',
      format: 'DD/MM/YYYY HH:mm',
      sort: true
    },
    {
      id: 'uploadDate',
      label: 'NEW-TRANSLATE.COMMON.UPLOAD-DATE',
      name: 'uploaded_date',
      type: 'date',
      width: '180px',
      minWidth: '180px',
      format: 'DD/MM/YYYY HH:mm',
      sort: true
    },
    {
      id: 'buyerName',
      label: 'NEW-TRANSLATE.COMMON.BUYER-NAME',
      name: 'buyer_name',
      type: 'string',
      width: '140px',
      minWidth: '140px',
      sort: true
    },
    {
      id: 'branchName',
      label: 'NEW-TRANSLATE.COMMON.BRANCH-NAME',
      name: 'branch_fullname',
      type: 'string',
      width: '140px',
      minWidth: '140px',
      sort: true,
      defaultValue: '-'
    },
    {
      id: 'documentType',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE',
      name: 'rd_document_type',
      type: 'document-type',
      width: '150px',
      minWidth: '150px',
      sort: true
    },
    {
      id: 'sales',
      label: 'NEW-TRANSLATE.COMMON.SALES',
      name: 'total_items_total_payment',
      type: 'number',
      width: '150px',
      minWidth: '150px',
      format: '0.2-2',
      defaultValue: '0',
      sort: true
    },
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    corporate_branch_id: new FormControl(null),
    rd_document_type: new FormControl(null),
    docu_no: new FormControl(undefined),
    docu_date_period: new FormControl(undefined, [ Validators.required ]),
    buyer_name: new FormControl(undefined),
    upload_date_period: new FormControl(undefined)
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isDocumentSearchPermission()) {
      this.router.navigate(['/']);
    }
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (this.authenticationService.isViewerImpAndHelp()) {
      removeColumns([ 'checkbox' ]);
    }
    if (this.authenticationService.isViewerOperation()) {
      removeColumns([ 'checkbox' ]);
    }
    this.columns = columns;
  }

  public isPermissionDatatableTools(): boolean {
    return !this.authenticationService.isViewerImpAndHelp() && !this.authenticationService.isViewerOperation();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBranch(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, branches, documentType ]) => {
        this.documentType = documentType;
        this.companies = companies;
        this.defaultBranches = branches.results;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
          this.filterBranches(defaultCompany);
        } else {
          this.companies.unshift(this.allSelect);
          this.filterBranches();
        }
        this.setPeriodDocumentDate();
        this.loadDocumentSearch();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  public setPeriodDocumentDate(): void {
    this.form.controls['docu_date_period'].setValue([ moment(this.currentDate).subtract(60, 'days').toDate(), this.currentDate ]);
  }

  public filterBranches(companyId?: number | string | null) {
    this.form.controls['corporate_branch_id'].setValue(null);
    this.branches = Object.assign([] as BranchModel[], this.defaultBranches)
                      .filter(x => x.corporate === companyId || !companyId)
                      .map(x => {
                        const filterName = x.full_name;
                        return { name_th: filterName, name_en: filterName, id: x.id };
                      });
    this.branches.unshift(this.allSelect);          
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadBranch(): Observable<ResponseMessageBranchModel> {
    return this.apiService
        .getBranch()
        .pipe(
          first(),
          map(x => x as ResponseMessageBranchModel)
        );
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.sortBy = undefined;
    this.sortMode = undefined;
    this.datatablesElement?.reset();
    this.loadDocumentSearch();
  }

  public onClear(): void {
    const defaultCorportateId = this.companies.length === 1 ? this.companies[0].id : null;
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(defaultCorportateId);
          formControl['corporate_branch_id'].setValue(null);
          formControl['rd_document_type'].setValue(null);
          formControl['docu_no'].setValue(undefined);
          formControl['buyer_name'].setValue(undefined);
          formControl['upload_date_period'].setValue(undefined);
    this.filterBranches(defaultCorportateId);
    this.setPeriodDocumentDate();
    this.pages = 1;
    this.submitted = false;
    this.sortBy = undefined;
    this.sortMode = undefined;
    this.datatablesElement?.reset();
    this.loadDocumentSearch();
  }

  public pageChanged(): void {
    this.loadDocumentSearch();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadDocumentSearch();
  }

  public onSortEvent(): void {
    this.loadDocumentSearch();
  }

  private loadDocumentSearch(): void {
    this.rows = [];
    this.selectedRows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const payload: PayloadDocumentSearchModel = {
      items_per_page: this.limitRow,
      company_id: formValue['corporate_id'] ?? undefined,
      corporate_branch: formValue['corporate_branch_id'] ?? undefined,
      header_issue_date_start: moment(formValue['docu_date_period'][0]).format('YYYY-MM-DD'),
      header_issue_date_end: moment(formValue['docu_date_period'][1]).format('YYYY-MM-DD'),
      uploaded_date_start: formValue['upload_date_period']?.length === 2 ? moment(formValue['upload_date_period'][0]).format('YYYY-MM-DD') : undefined,
      uploaded_date_end: formValue['upload_date_period']?.length === 2 ? moment(formValue['upload_date_period'][1]).format('YYYY-MM-DD') : undefined,
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      document_number: formValue['docu_no'] ?? undefined,
      buyer_name: formValue['buyer_name'] ?? undefined,
      sort: this.sortBy,
      sort_type: this.sortMode
    };
    this.httpClientService
      .post(`${ environment.apiURL }/api/search_files/?page=${ this.pages }`, payload)
      .pipe(
        first(),
        map(x => x as ResponseMessageDocumentSearchModel)
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results;
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onDownloadReport(type: string): void {
    const downloads = Object.assign([] as DocumentSearchModel[], this.selectedRows)
                        .map(x => {
                          return {
                            type: type,
                            filename: x.unique_invoice_number + type,
                            url: `${ environment.apiURL }/api/download_document/${ type.replace('.', '') }/${ x.id }`
                          } as PayloadDownloadFileModel;
                        });
    this.isLoadingOther = true;
    this.handleModalService
      .downloadFileModal(downloads, true)
      .result
      .then(() => this.isLoadingOther = false)
      .catch(() => this.isLoadingOther = false)
  }


}